<template>
  <div class="productDetail">
    <PageTitle ttl-en="PRODUCT" ttl-jp="_製品紹介" />
    <div class="inner">
      <div class="productDetail__outline">
        <h1 class="productDetail__outline__ttl js-scroll">
          自動外観検査
        </h1>
        <p class="productDetail__outline__txt js-scroll">
          自動検査システムの構築では、センシングデバイス（カメラ等）、
          照明、画像処理システムの３つが検査の効率や信頼性を大きく左右します。
          これらを最適化することで、安定的かつ高精度な不具合の発見が可能になります。
          この領域でも株式会社Luci(ルーチ)は照明器具の提供にとどまらず、
          ソフトウエアの開発、照明の適切な調光、配光など、コンサルティングを通じて先進的なソリューションを提供しています。
        </p>
      </div>
      <div class="productDetail__block js-scroll">
        <div class="productDetail__block__imgs js-scroll">
          <div class="productDetail__block__imgs__panel">
            <p class="ttl">
              Luciの技術
            </p>
            <p class="img">
              <img src="/genbashiko/img/product/product01/image01.jpg" alt="" />
            </p>
          </div>
          <div class="productDetail__block__imgs__panel">
            <p class="ttl">
              PROPHESEE
            </p>
            <p class="img">
              <img src="/genbashiko/img/product/product01/image02.jpg" alt="" />
            </p>
          </div>
        </div>
        <div class="productDetail__point">
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT1
            </p>
            <p class="productDetail__point__block__ttl">
              より高精度な外観検査を実現するLED照明を開発
            </p>
            <p class="productDetail__point__block__txt">
              1秒間に数十枚という非常にハイスピードな検査では、3つの要素をベストマッチングさせることで、画像認識の大幅な精度レベル向上が可能になります。株式会社Luci(ルーチ)では自動検査に最適なLED照明を開発し、センシングデバイスの撮像スピードに合わせて、LEDの周波数を細かく制御して最適化。撮像スピードとLEDが明滅するタイミングを完全にシンクロさせるデジタルライティングを実現し、検査精度の向上に成功しています。
            </p>
          </div>
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT2
            </p>
            <p class="productDetail__point__block__ttl">
              人間の視神経の仕組みを模したシステムを導入
            </p>
            <p class="productDetail__point__block__txt">
              センシングデバイスとシステムの構築に関しては、グループ会社である株式会社コンテンツとのコラボレーションを通じて、最先端のシステム導入も実現可能です。その一例がフランスのPROPHESEE社が開発した、対象物が変化した部分のみをとらえるイベントセンサーの活用です。これを用いることで、従来は1台あたり10～20分かかっていたある検査工程が数十秒に短縮されるなど、圧倒的な生産性の向上を実現しています。
            </p>
          </div>
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT3
            </p>
            <p class="productDetail__point__block__ttl">
              外観検査の対象にマッチした自動化システムを提案
            </p>
            <p class="productDetail__point__block__txt">
              検査対象や工程に合わせたシステムの提案が非常に重要です。株式会社Luci(ルーチ)では外観検査内容や目的に応じて、画像解析ソフトやAI技術を活用したシステムを構築しています。ベテラン作業員が欠陥を発見する職人技に頼らざるを得なかった工程においても、適切な自動化システムの導入によって属人化を廃し、省人化が可能になります。これによって製品の歩留り率や直行率の大幅な向上が期待できるとともに、コストダウンを力強くサポートします。
            </p>
          </div>
        </div>
        <div class="productDetail__service">
          <h2 class="productDetail__service__ttl js-scroll">
            サービス
          </h2>
          <ul class="productDetail__service__list">
            <li class="productDetail__service__item js-scroll">
              <div class="txtBlock">
                <p class="txtBlock__ttl">
                  塗装ブツAI 検知システム
                </p>
                <p class="txtBlock__txt">
                  目視検査でのタクトタイムの削減と効率化をお手伝いするシステムです。ロボットアームに取り付けることで、自動で1mm
                  以下の原因から生じた塗装ブツも検知可能です。
                </p>
                <div class="productDetail__service__cate case">
                  <p>担当領域：</p>
                  <ul>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                      >
                        塗装完成車工程
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
                      >
                        ボデー・プレス工程
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                      >
                        検査現場を省人化したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                      >
                        現状の課題を確認したい
                      </router-link>
                    </li>
                  </ul>
                </div>
                <a
                  class="txtBlock__link btn-or"
                  href="/genbashiko/upload/download_pdf/AIIOT_flyer2.pdf" target="_blenk"
                >
                  <span>システムの詳細を見る</span>
                </a>
              </div>
              <div class="imageBlock">
                <img src="/genbashiko/img/product/product01/image03.jpg" alt="" />
              </div>
            </li>
            <li class="productDetail__service__item js-scroll">
              <div class="txtBlock">
                <p class="txtBlock__ttl">
                  穴数/形状検知システム
                </p>
                <p class="txtBlock__txt">
                  プレスなど穴の形状と穴の数を正確に検出し、目視検査でのタクトタイムの削減と効率化をお手伝いするシステムです。微細な穴でも検出ができ、「外光／室内光／人などの移動による光の遮光」の影響を受けにくいので設置場所の自由度が高いです。
                </p>
                <div class="productDetail__service__cate case">
                  <p>担当領域：</p>
                  <ul>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                      >
                        既存の検査場を改修したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                      >
                        検査現場を省人化したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                      >
                        塗装完成車工程
                      </router-link>
                    </li>
                  </ul>
                </div>
                <a
                  class="txtBlock__link btn-or"
                  href="/genbashiko/upload/download_pdf/穴数形状検知システム.pdf" target="_blenk"
                >
                  <span>システムの詳細を見る</span>
                </a>
              </div>
              <div class="imageBlock">
                <img src="/genbashiko/img/product/product01/image04.jpg" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="productDetail__block js-scroll">
        <div class="productDetail__block__imgs js-scroll">
          <div class="productDetail__block__imgs__panel">
            <p class="ttl">
              Luciの技術
            </p>
            <p class="img">
              <img src="/genbashiko/img/product/product01/image01.jpg" alt="" />
            </p>
          </div>
          <div class="productDetail__block__imgs__panel">
            <p class="ttl">
              Rutilea
            </p>
            <p class="img border">
              <img src="/genbashiko/img/product/product01/image05.jpg" alt="" />
            </p>
          </div>
        </div>
        <div class="productDetail__point">
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT1
            </p>
            <p class="productDetail__point__block__ttl">
              ノーコード製品を導入し、開発時間を大幅削減
            </p>
            <p class="productDetail__point__block__txt">
              業界特有のユースケースを開発し、数多くの画像処理AI、ルールベースを導入。課題解決までのリードタイムを短縮することができます。ノーコード製品のため、エンジニア不要で広範囲の課題解決に対応できます。また、スクラッチ開発が不要なため、開発コストも削減可能です。新しいワークの検査でもノーコードで簡単に再設定できます。
            </p>
          </div>
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT2
            </p>
            <p class="productDetail__point__block__ttl">
              照度差ステレオ法でハレーション対策
            </p>
            <p class="productDetail__point__block__txt">
              画像検査で一番の課題は照明や外乱光による反射や白飛びだと思います。このRutilea社製ImageProでは照度差ステレオ法を用い上記課題を解決することができます。照度差ステレオ法とは、照明×ソフトウェアを連動させ合成画像を作成することです。弊社Luciの照明技術とRutilea社のソフトウェア技術を合わせることで画像検査に最適な環境作りをすることが可能です。
            </p>
          </div>
        </div>
        <div class="productDetail__service">
          <h2 class="productDetail__service__ttl js-scroll">
            サービス
          </h2>
          <ul class="productDetail__service__list">
            <li class="productDetail__service__item js-scroll">
              <div class="txtBlock">
                <p class="txtBlock__ttl">
                  ImagePro
                </p>
                <p class="txtBlock__txt">
                  マシンビジョン用の開発ソフトウェアです。画像処理プログラミングで工数を95%削減することができます。簡単なマウス操作でプログラミング不要なので属人的にならずにご使用いただけます。
                </p>
                <div class="productDetail__service__cate case">
                  <p>担当領域：</p>
                  <ul>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                      >
                        塗装完成車工程
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: 'エンジン・パワートレイン工程' }}"
                      >
                        エンジン・パワートレイン工程
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                      >
                        検査現場を省人化したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                      >
                        現状の課題を確認したい
                      </router-link>
                    </li>
                  </ul>
                </div>
                <a
                  class="txtBlock__link btn-or"
                  href="/genbashiko/upload/download_pdf/Luci×ルテリア.pdf" target="_blenk"
                >
                  <span>システムの詳細を見る</span>
                </a>
              </div>
              <div class="imageBlock">
                <img src="/genbashiko/img/product/product01/image06.jpg" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="productDetail__block js-scroll">
        <div class="productDetail__block__imgs js-scroll">
          <div class="productDetail__block__imgs__panel">
            <p class="ttl">
              Luciの技術
            </p>
            <p class="img">
              <img src="/genbashiko/img/product/product01/image01.jpg" alt="" />
            </p>
          </div>
        </div>
        <div class="productDetail__point">
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT1
            </p>
            <p class="productDetail__point__block__ttl">
              より高精度な外観検査を実現するLED照明を開発
            </p>
            <p class="productDetail__point__block__txt">
              1秒間に数十枚という非常にハイスピードな検査では、3つの要素をベストマッチングさせることで、画像認識の大幅な精度レベル向上が可能になります。株式会社Luci(ルーチ)では自動検査に最適なLED照明を開発し、センシングデバイスの撮像スピードに合わせて、LEDの周波数を細かく制御して最適化。撮像スピードとLEDが明滅するタイミングを完全にシンクロさせるデジタルライティングを実現し、検査精度の向上に成功しています。
            </p>
          </div>
          <div class="productDetail__point__block js-scroll">
            <p class="productDetail__point__block__point">
              POINT2
            </p>
            <p class="productDetail__point__block__ttl">
              外観検査の対象にマッチした自動化システムを提案
            </p>
            <p class="productDetail__point__block__txt">
              検査対象や工程に合わせたシステムの提案が非常に重要です。株式会社Luci(ルーチ)では外観検査内容や目的に応じて、画像解析ソフトやAI技術を活用したシステムを構築しています。ベテラン作業員が欠陥を発見する職人技に頼らざるを得なかった工程においても、適切な自動化システムの導入によって属人化を廃し、省人化が可能になります。これによって製品の歩留り率や直行率の大幅な向上が期待できるとともに、コストダウンを力強くサポートします。
            </p>
          </div>
        </div>
        <div class="productDetail__service">
          <h2 class="productDetail__service__ttl js-scroll">
            サービス
          </h2>
          <ul class="productDetail__service__list">
            <li class="productDetail__service__item js-scroll">
              <div class="txtBlock">
                <p class="txtBlock__ttl">
                  自動調色調光システム
                </p>
                <p class="txtBlock__txt">
                  塗装検査向けに考えられた、作業員の目への負担軽減や検出率アップの改善をお手伝いするシステムです。カメラで対象ワークを撮像することで、あらかじめ設定しておいた塗装色を判別し、照明の色味・明るさを自動で変化させることができます。
                </p>
                <div class="productDetail__service__cate case">
                  <p>担当領域：</p>
                  <ul>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                      >
                        既存の検査場を改修したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                      >
                        検査現場を省人化したい
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="category"
                        :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                      >
                        塗装完成車工程
                      </router-link>
                    </li>
                  </ul>
                </div>
                <a
                  class="txtBlock__link btn-or"
                  href="/genbashiko/upload/download_pdf/PA-jido-dimming.pdf" target="_blenk"
                >
                  <span>システムの詳細を見る</span>
                </a>
              </div>
              <div class="movieBlock">
                <iframe
                  src="https://www.youtube.com/embed/kebAfJDquuM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="js-scroll">
        <ReturnToIndexBtn path="/product" />
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import ReturnToIndexBtn from '@/components/ReturnToIndexBtn.vue'
import scrollAnimMixin from '../../mixins/scrollAnimMixin.js'

export default {
  components: {
    PageTitle,
    ReturnToIndexBtn
  },
  mixins: [scrollAnimMixin],
  head: {
    title: {
      inner: '自動外観検査 | 製品紹介'
    },
    meta: [
      {
        property: 'og:title',
        content: '自動外観検査 | 製品紹介 | GENBA SHIKO'
      },
      {
        name: 'description',
        content:
          '自動車製造工場の検査工程に特化した当社の自動検査システムをご覧ください。外観検査を自動化することで、自動車産業の様々な課題に対して、車体品質の確保と業務効率化をサポートします。'
      },
      {
        property: 'og:description',
        content:
          '自動車製造工場の検査工程に特化した当社の自動検査システムをご覧ください。外観検査を自動化することで、自動車産業の様々な課題に対して、車体品質の確保と業務効率化をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/product/product01'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
<style lang="scss" scoped>
.productDetail {
  padding-bottom: 42px;
  &__outline {
    margin-top: 80px;
    &__ttl {
      font-size: 3.6rem;
      &::after {
        content: '';
        display: block;
        background: $orColor;
        width: 40px;
        height: 4px;
        margin: 30px auto 0;
      }
      &.js-scroll {
        transform: translateY(0);
      }
    }
    h2 {
      font-size: 3rem;
      margin-top: 60px;
      &.js-scroll {
        transform: translateY(50px);
      }
    }
    &__txt {
      line-height: 2.1;
      margin-top: 40px;
      font-size: 1.6rem;
    }
    + .productDetail__block {
      .productDetail__block__imgs {
        margin-top: 80px;
      }
    }
  }
  &__block {
    position: relative;
    &__imgs {
      display: flex;
      justify-content: center;
      margin-top: 230px;
      position: relative;
      align-items: center;
      gap: 60px;
      &__panel {
        flex: 0 0 510px;
        .img {
          &.border {
            border: 1px solid #e3ddd6;
          }
        }
        .ttl {
          color: #cec9c3;
          font-size: 2.4rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        + .productDetail__block__imgs__panel {
          .img {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              display: block;
              top: 50%;
              left: -52px;
              transform: translateY(-50%);
              width: 45px;
              height: 44px;
              background: url('/img/common/cross.png') center/cover no-repeat;
            }
          }
        }
      }
    }
    + .productDetail__block {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background: #ff9e16;
        width: 100px;
        height: 3px;
      }
    }
  }
  &__point {
    margin-top: 80px;
    &__block {
      background: #f2f0ec;
      padding: 40px;
      &__point {
        color: #fff;
        font-size: 1.6rem;
        font-family: $Lato;
        display: inline-block;
        background: $orColor;
        padding: 7px 16px;
        font-weight: bold;
      }
      &__ttl {
        font-size: 2.2rem;
        margin-top: 20px;
        text-align: left;
      }
      &__txt {
        line-height: 2.1;
        margin-top: 30px;
      }
      + .productDetail__point__block {
        margin-top: 50px;
      }
    }
  }
  &__service {
    margin-top: 100px;
    h2 {
      font-size: 3rem;
      text-align: left;
    }
    &__list {
      margin-top: 40px;
    }
    &__item {
      display: flex;
      align-items: center;
      border: 2px solid #f2f0ec;
      .txtBlock {
        background: #f2f0ec;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &__ttl {
          font-size: 2.1rem;
          letter-spacing: 0.2em;
          text-align: center;
          line-height: 1;
        }
        &__txt {
          line-height: 2.1;
          margin-top: 30px;
          letter-spacing: 0.05em;
        }
        &__link {
          display: flex;
          align-items: center;
          margin: 40px auto 0;
          font-size: 1.4rem;
          padding: 20px 35px;
          width: 250px;
          height: 50px;
          border-radius: 30px;
          span {
            padding-left: 8px;
            &::before {
              right: 24px;
            }
            &::after {
              right: 22px;
            }
          }
        }
      }
      .movieBlock {
        position: relative;
        flex: 0 0 620px;
        max-width: 620px;
        &::before {
          content: '';
          display: block;
          padding-top: 56.25%;
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .imageBlock {
        position: relative;
        flex: 0 0 620px;
        max-width: 620px;
        img {
          width: 100%;
        }
      }
      + .productDetail__service__item {
        margin-top: 50px;
      }
    }
    &__cate {
      margin-top: 20px;
      p {
        font-weight: bold;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
      }
      &.process {
        margin-top: 60px;
      }
      .category {
        padding: 5px 15px;
      }
      &.case {
      }
      + .productDetail__lineup__cate {
        margin-top: 40px;
      }
    }
  }
  .returnBtn {
    margin-top: 100px;
  }
  &__nav {
    margin-top: 100px;
    border-top: 1px solid #cec9c3;
    &__ttl {
      margin-top: 50px;
      text-align: left;
      span {
        font-size: 2.6rem;
        font-family: $Lato;
        margin-right: 10px;
      }
    }
    ul {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        flex: 0 0 47%;
        .ttl {
          font-size: 2.2rem;
          font-weight: bold;
          margin-top: 30px;
          line-height: 1.45;
        }
        img {
          transition: all 0.3s ease-in;
        }
        &:hover {
          img {
            opacity: 0.7;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }
  &__download {
    width: 100%;
    text-align: center;
    margin: 40px 0 60px;
    a {
      color: $orColor;
      font-size: 2.2rem;
      font-weight: bold;
      border-bottom: 1px solid $orColor;
      padding-bottom: 5px;
      display: inline-block;
      transition: all 0.3s ease-in;
      &:hover {
        border-color: transparent;
        transition: all 0.3s ease-out;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .productDetail {
    padding-bottom: 10px;
    &__outline {
      margin-top: 40px;
      &__ttl {
        font-size: 2.1rem;
        &::after {
          margin-top: 20px;
        }
      }
      h2 {
        font-size: 1.6rem;
        margin-top: 30px;
      }
      &__txt {
        font-size: 1.2rem;
        margin-top: 20px;
        letter-spacing: 0;
      }
      + .productDetail__block {
        .productDetail__block__imgs {
          margin-top: 40px;
        }
      }
    }
    &__block {
      margin-top: 40px;
      .ttl {
        font-size: 1.2rem;
      }
      &__imgs {
        gap: 30px;
        margin-top: 100px;
        &__panel {
          flex: 0 0 45%;
          + .productDetail__block__imgs__panel {
            .img {
              position: relative;
              &::before {
                content: '';
                left: -27px;
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
      + .productDetail__block {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          background: #ff9e16;
          width: 70px;
        }
      }
    }
    &__point {
      margin-top: 40px;
      &__block {
        padding: 20px 30px;
        &__point {
          font-size: 1.2rem;
          padding: 5px 10px;
        }
        &__ttl {
          font-size: 1.3rem;
          margin-top: 15px;
        }
        &__txt {
          font-size: 1.1rem;
          margin-top: 15px;
        }
      }
    }
    &__service {
      margin-top: 40px;
      h2 {
        font-size: 1.6rem;
      }
      &__item{
       align-items: normal;
      }
      &__list {
        margin-top: 20px;
        li {
          flex-direction: column-reverse;
          .txtBlock {
            padding: 25px 20px 30px;
            max-width: 100%;
            &__ttl {
              font-size: 1.3rem;
            }
            &__txt {
              font-size: 1.1rem;
              margin-top: 20px;
            }
            &__link {
              margin-top: 25px;
              padding: 15px 20px;
              height: 50px;
              width: 230px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                padding: 0 5px 0 0;
                &::before {
                  top: 27px;
                }
                &::after {
                  top: 24px;
                }
              }
            }
          }
          .movieBlock,
          .imageBlock {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
      &__cate {
        &.process {
          margin-top: 35px;
        }
        p {
          font-size: 1.2rem;
        }
        + .productDetail__lineup__cate {
          margin-top: 20px;
        }
      }
    }
    .returnBtn {
      margin-top: 30px;
    }
    &__nav {
      margin-top: 50px;
      ul {
        display: block;
        li {
          .ttl {
            font-size: 1.4rem;
            margin-top: 20px;
          }
          + li {
            margin-top: 40px;
          }
        }
      }
    }
    &__download {
      margin: 20px 0 0;
      a {
        font-size: 1.3rem;
      }
    }
  }
}
</style>
